import React from 'react';
import {
    formatter, formatterDecimal
} from "../../../../utils/common";

const Widget = ({ textColorClass, value, label }) => {
    textColorClass = textColorClass ? textColorClass : 'text-dark';
    return (
        <div className="col-md-6 col-xl">
            <div className={`widget-rounded-circle card-box`}>
                <div className="text-right">
                    <h5 className={`mt-1 ${textColorClass}`}>
                        <span>{value}</span>
                    </h5>
                    <p className="text-muted mb-1 text-truncate">{label}</p>
                </div>
            </div>
        </div>
    );
};

const ShippingIncomeTotalSummary = ({ financesSummaryState }) => {
    const totalRow = financesSummaryState.shippingIncomeDetailsTableRow;
    const totalSummary = financesSummaryState.shippingIncomeDetailsTotalSummary;

    const widgetsData = [
        {
            value: totalRow,
            label: "Total Records",
        },
        {
            value: formatter.format(totalSummary ? totalSummary.total_shipping_charges : 0),
            label: "Total Shipping",
        },
        {
            value: formatter.format(totalSummary ? totalSummary.total_shipping_tax : 0),
            label: "Total Taxes",
        },
        {
            // textColorClass: 'text-danger',
            value: formatter.format(totalSummary ? totalSummary.total_net_shipping : 0),
            label: "Total Net Shipping",
        },
    ];

    return (
        <div className="row">
            {widgetsData.map((widget, index) => (
                <Widget
                    key={index}
                    textColorClass={widget.textColorClass}
                    value={widget.value}
                    label={widget.label}
                />
            ))}
        </div>
    );
};

export default ShippingIncomeTotalSummary;
