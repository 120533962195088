import React, { Fragment, useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Popover,
    PopoverBody,
    Row
} from "reactstrap";

import { useHistory } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import moment from "moment";
import { DateRangePicker } from 'rsuite';

import Breadcrumb from "../../../../layout/breadcrumb";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import { mapSearchAutoSuggestion } from "../../../../utils/mapper";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "../../../../store/withReducer";
import sellerReducer from "../../store";
import { sellerAutoSuggestionApi } from "../../../../services/manage-product/productService";
import { agentAutoSuggestionApi } from "../../../../services/seller";
import DatePicker from "../../../../share-components/ui/DateRangePicker";

import {
    downloadAdminCommissionExport,
    EmailAdminCommissionExport,
    getSellerSettings,
    setSettings
} from "../../store/sellerSlice";

import { getCommissions } from "../../store/sellerCommissionSlice";

const { afterToday } = DateRangePicker;

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const SellerCommission = (props) => {
    let history = useHistory();
    const dispatch = useDispatch();

    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 7);
    const authData = useSelector(({ authReducer }) => authReducer);
    const [seller, setSeller] = useState(authData.defaultSeller);
    const [agent, setAgent] = useState(null);
    const [loading, setLoading] = useState(false);
    const userState = useSelector(({ authReducer }) => authReducer.data);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const [startDate, endDate] = dateRange;
    const sellerState = useSelector(({ seller }) => seller.seller);
    const sellerCommission = useSelector(({ seller }) => seller.sellerCommission);

    const [lastFillters, setLastFillters] = useState(null);
    const [filter, setFilter] = useState({
        page: 1,
        pageSize: 10,
        seller_id: seller && seller.value ? seller.value : '',
        agent_id: agent,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        sort_field: 'id', 
        sort_order: 'asc'
    });

    useEffect(() => {
        setFilter(prev => ({
            ...prev,
            page: 1,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
        }));
    }, [startDate, endDate]);


    useEffect(() => {
        setFilter(prev => ({
            ...prev,
            page: 1,
            seller_id: seller ? seller.value : '',
        }));
    }, [seller]);

    const dataMenu = [
        {
            type: 0,
            text: "Seller Commission"
        }
    ];

    const tableColumns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortField: 'id', // Custom backend column mapping
            sortable: true,
            center: false,
            omit: true,
        },
        {
            name: 'seller_name',
            selector: row => row.seller_name,
            sortField: 'shop_name', // Custom backend column mapping
            sortable: true,
            center: false,
            omit: true,
        },
        {
            name: 'Seller Name',
            selector: row => row.name,
            sortField: 'name', // Custom backend column mapping
            sortable: true,
            center: false,
        },
        {
            name: 'Total (£)',
            selector: row => row.format_total_price,
            sortField: 'total', // Custom backend column mapping
            sortable: true,
            center: false,
        },
        {
            name: 'Total Excl. Vat (£)',
            selector: row => row.format_total_price_after_vat,
            sortField: 'total_exclude_vat', // Custom backend column mapping
            sortable: true,
            center: false,
        },
        {
            name: 'Admin Commission (£)',
            selector: row => row.format_admin_commission,
            sortField: 'admin_commission', // Custom backend column mapping
            sortable: true,
            center: false,
        },
        {
            name: 'Agent Commission (£)',
            selector: row => row.format_agent_commission,
            sortField: 'agent_commission', // Custom backend column mapping
            sortable: true,
            center: false,
        },
        {
            name: 'Action',
            selector: row => (
                <div>
                    <a className={(loading ? "disabled" : "") + " cursor-pointer"} onClick={() => { onAdminDownload(row, "pdf"); }}>
                        <i className="fa fa-file-pdf-o" style={{ fontSize: 16, color: 'rgb(226,203,47)' }}></i>
                    </a>
                    <a className={(loading ? "disabled" : "") + " cursor-pointer ml-3"} onClick={() => { onAdminDownload(row, "csv"); }}>
                        <i className="fa fa-file-excel-o" style={{ fontSize: 16, color: 'rgb(40, 167, 69)' }}></i>
                    </a>
                    <a className={(loading ? "disabled" : "") + " cursor-pointer ml-3"} onClick={() => { onAdminEmail(row); }}>
                        <i className="fa fa-envelope-o" style={{ fontSize: 16, color: 'rgb(226,68,47)' }}></i>
                    </a>
                    <a className={(loading ? "disabled" : "") + " cursor-pointer ml-3"} onClick={() => { onAdminCommissionView(row); }}>
                        <i className="fa fa-eye" style={{ fontSize: 16, color: 'rgb(112,71,64)' }}></i>
                    </a>

                    {row.loading && (
                        <a>
                            <i className="fa fa-spinner fa-spin fa-lg spinner ml-3" style={{ fontSize: 16 }}></i>
                        </a>
                    )}
                </div>
            ),
            sortable: false,
            center: false,
        }
    ];

    useEffect(() => {
        setSeller(authData.defaultSeller)
    }, [authData.defaultSeller])

    const onAdminDownload = (row, type) => {
        const data = {
            seller_id: row.id,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            export_type: type,
        }
        setLoading(true);
        dispatch(downloadAdminCommissionExport(data)).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${row.name}_${(new Date().toJSON().slice(0, 10))}.${type}`); //or any other extension
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        });
    }

    const onAdminEmail = (row) => {
        const data = {
            seller_id: row.id,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
        }
        setLoading(true);
        dispatch(EmailAdminCommissionExport(data)).then(res => {
            setLoading(false);
        });
    }

    const onAdminCommissionView = (row) => {
        const data = {
            seller_id: row.id,
            seller_name: row.seller_name,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
        }

        history.push(`/commission/detail-commission?seller=${data.seller_id}&seller_name=${data.seller_name}&start_date=${data.start_date}&end_date=${data.end_date}`);
    }

    useEffect(() => {
        dispatch(getSellerSettings()).then(res => {
            if (!res.error) {
                const results = {
                    settings: res.payload,
                    id: null
                }
                dispatch(setSettings(results));
            }
        });
    }, [dispatch]);

    useEffect(() => {
        fetchData();
    }, [filter]);

    const fetchData = () => {
        if (JSON.stringify(filter) == JSON.stringify(lastFillters)) return;

        setLastFillters({ ...filter })
        setLoading(true);
        dispatch(getCommissions({ ...filter })).then(res => {
            if (!res.error) {
                setLoading(false);
            }
        });
    }

    const handleSort = (column, sortDirection) => {        
        setFilter(prev => ({
            ...prev,
            sort_field: column.sortField || column.selector, // Assumes column.selector maps to backend column name
            sort_order: sortDirection
        }));
    };

    const sellerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)))
            }, 200);
        });

    const agentPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(agentAutoSuggestionApi(data)))
            });
        });

    const onPageSizeChange = (newPageSize) => {
        setFilter(prev => ({
            ...prev,
            page: 1,
            pageSize: newPageSize === 'All' ? sellerCommission.sellerCommissionTableRow : newPageSize
        }));
    }

    const onPageChange = (e) => {
        setFilter({ ...filter, page: e })
    }

    return (
        <Fragment>
            <Breadcrumb title="Seller Commission" data={dataMenu} />
            <Container fluid={true} className="product-wrapper">
                <div className="product-grid mb-3">
                    <div className="feature-products">
                        <Row>
                            <Col sm="4">
                                <div className="dashboard-datepicker">
                                    <DatePicker
                                        dateRange={dateRange}
                                        dateRangeChange={(date) => {
                                            setDateRange(date)
                                        }}
                                        defaultValue={[TodayDate, sevenDays]}
                                        disabledDate={afterToday()}
                                    />
                                </div>
                            </Col>
                            <Col sm="4">
                                <AsyncSelect
                                    className="select2-filter"
                                    isClearable={true}
                                    defaultOptions
                                    styles={customStyles}
                                    placeholder="Select Seller"
                                    loadOptions={sellerPromiseOption}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    value={seller}
                                    onChange={(e) => {
                                        setSeller(e);
                                    }}
                                />
                            </Col>

                            {/* <Col sm="4">
                                {((userState.user.role.name !== "Seller") || (userState.user.is_primary_seller)) && <Select
                                    className="select2-filter"
                                    isClearable={true}
                                    defaultOptions
                                    placeholder="Select Agent / Sales Person"
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    cacheOptions
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    styles={customStyles}
                                    options={sellerState.settings.agents}
                                    onChange={(e) => {
                                        setAgent(e ? e.id : null)
                                    }}

                                />}
                            </Col> */}
                        </Row>
                    </div>
                </div>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <div className="table-responsive product-table">
                                    <CommonDataTable
                                        headerColumns={tableColumns}
                                        gridData={sellerCommission.sellerCommissionLists}
                                        noAction
                                        progressPending={loading}
                                        paginationServer
                                        paginationTotalRows={sellerCommission.sellerCommissionTableRow}
                                        paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                        paginationDefaultPage={filter.page}
                                        paginationPerPage={filter.pageSize}
                                        onChangePage={onPageChange}
                                        onChangeRowsPerPage={onPageSizeChange}
                                        onSort={handleSort}
                                        sortServer
                                    />
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default withReducer([{ seller: sellerReducer }])(SellerCommission);